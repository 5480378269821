<template>
   <a-row :gutter="25" class="mt-4" style="justify-content: center;">
    <a-col
        v-if="dataView.showLoader"
        :xxl="24" 
        :lg="24" 
        :md="24"
        :xs="24">
      <a-spin size="medium" style="position:relative; left: 50%;margin-top: 14rem;"/>
    </a-col>
    <a-col
        v-if="!dataView.showLoader"
        :xxl="14" 
        :lg="14" 
        :md="14"
        :xs="24"
        style="font-family: Avenir Roman">
        <a-row class="container-view">
            <a-col class="mb-4" :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;">
                <h3 class="bold-normal">¡Tu registro como {{ dataView.module }} ha sido exitoso!</h3>
            </a-col>
            <a-col :xxl="12" :lg="12" :md="12" :xs="24" style="text-align: center;">
              <a-row class='w-100'>
                <a-col  :xxl="24"
                        :lg="24"
                        :md="24"
                        :xs="24"
                  v-if="dataView.event.hasQr && dataView.event.qr"
                >
                  <span class="bold-normal">Código QR válido</span>
                  <br><br>
                  <img height="150" width="150" :src="dataView.event?.qr" alt="">
                </a-col>
              </a-row>
              <br><br>
              <a-row class='w-100'>
                <a-col  :xxl="24"
                        :lg="24"
                        :md="24"
                        :xs="24" v-if="dataView.event.code">
                  <span class="bold-normal">Código de acceso</span>
                  <br><br>
                  <span class="normal">{{dataView.event?.code || '-'}}</span>
                </a-col>
              </a-row>


            </a-col>
             <a-col  :xxl="12" :lg="12" :md="12" :xs="24">
                <row>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataView.event?.event_name != null">
                        <span class="bold-normal">Nombre del evento:</span>
                    </a-col>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataView.event?.event_name != null">
                        <span class="normal title-gray">{{ dataView.event?.event_name }}</span>
                    </a-col>
                    <br>
                    <template
                      v-if="dataView.event?.headquarters != null && dataView.event?.headquarters.find(sede=>sede.type=='face-to-face' || sede.type=='both')">
                      <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                        <span class="bold-normal">Sede:</span>
                      </a-col>
                      <div>
                        <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-for="(headquarter) in dataView.event?.headquarters" :key="headquarter">
                          <div v-if="headquarter.type != 'virtual'">
                            <span class="title-gray">- Presencial</span> <br>
                            <span class="title-gray">{{ headquarter.name }}, {{ headquarter.street }}, {{ headquarter.suburb ? headquarter.suburb+',' : headquarter.other_suburb ? headquarter.other_suburb+',' : ''}} {{ headquarter.municipality ? headquarter.municipality+',' : ''}} {{ headquarter.postal_code ? headquarter.postal_code : '' }}</span> <br>
                          </div>

                        </a-col>
                      </div>
                    </template>
                    <br>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                        <span class="bold-normal">Fecha de inicio del evento:</span>
                    </a-col>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataView.event?.date != null">
                        <span class="normal title-gray">{{ formatDate(dataView.event?.date) }} {{ removeSeconds(dataView.event?.hour) }}hrs</span>
                    </a-col>
                    <br>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                      <span class="bold-normal">Fecha de finalización del evento:</span>
                    </a-col>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="dataView.event?.event_end_date != null">
                      <span class="normal title-gray">{{ formatDate(dataView.event?.event_end_date) }} {{ removeSeconds(dataView.event?.end_time) }}hrs</span>
                    </a-col>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-else-if="dataView.event?.end_date != null">
                    <span class="normal title-gray">{{ formatDate(dataView.event?.end_date) }} {{ removeSeconds(dataView.event?.end_time) }}hrs</span>
                  </a-col>
                </row>
            </a-col>
            <br><br>
            <a-col v-if="dataView.event?.grouped_events" :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;margin-top: 2rem;">
                <h3 class="bold-normal">Eventos a los que asistiré</h3>
            </a-col>
            <a-col v-if="dataView.event?.grouped_events" class="mb-4" :xxl="24" :lg="24" :md="24" :xs="24">
                <a-row  v-for="(subevent) in dataView.event?.subevents" :key="subevent" class="mb-4 component-subevent" :xxl="24" :lg="24" :md="24" :xs="24">
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="border-bottom: 1px solid #CCCCCC; padding-bottom: 0.5rem;padding-top: 0.5rem;">
                        <span class="bold-normal">{{subevent.event_name}}</span>
                    </a-col>
                    <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="padding-top: 0.5rem;">
                        <a-row>
                            <a-col  :xxl="12" :lg="12" :md="24" :xs="24" >
                                <span class="bold-normal">Fecha de inicio del evento</span>
                                <br>
                                <span class="normal title-gray">{{ formatDate(subevent.event_start_date) }}</span>
                            </a-col>
                            <a-col  :xxl="12" :lg="12" :md="24" :xs="24" >
                                 <span class="bold-normal">Fecha de finalización del evento</span>
                                <br>
                                <span class="normal title-gray">{{ formatDate(subevent.event_end_date) }}</span>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row class="mt-4">
            <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;">
                <router-link :to="'/'">
                    <button class="btn-outlined-exit">
                        <span class="normal"> Volver al inicio </span>
                    </button>
                </router-link>
            </a-col>
        </a-row>
    </a-col>
   </a-row>
</template>

<script>

import { useRoute, useRouter } from "vue-router";
import { reactive } from 'vue';
import { useStore } from 'vuex';
import { getFileFolioRegisterToEvent } from '@/services/home';
import { formatDate, removeSeconds } from '@/utility/utility';

export default {
  name: 'ResponseQR',
  components: {
  },
  async setup() {
    const route = useRoute();
    const router = useRouter();

    const handleDownloadFile = (eventID, folio) => {
      getFileFolioRegisterToEvent(eventID).then((response) => {
        if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', "folio_" + folio + ".pdf");
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }).catch(() => {

      })
    }
    
    if(route.params.module == undefined){
        router.push({ path: '/home/events/' })
        
    } else {
        const event = route.params?.event ? JSON.parse(route.params?.event) : null;
        const folio = event != null ? event.folio : "0";
        if(route.params.eventID != undefined){
            handleDownloadFile(route.params.eventID, folio);
        }
    }

    const { dispatch } = useStore();
    dispatch('changeTitleHeader', route.params.module == undefined ? '' : 'Registro como ' + route.params.module);

    const dataView = reactive({
        showLoader: false,
        module: route.params.module,
        event: route.params?.event ? JSON.parse(route.params?.event) : []
    })


    return {
      dataView,
      formatDate,
      removeSeconds
    };
  },
};
</script>
<style scoped>
.container-view{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    height: auto;
    padding: 2rem 1.5rem 2rem 1.5rem;
}
.title-gray{
    color: #7B868C !important;
}
.btn-outlined-exit{
  border: 1px solid #636668;
  color: #636668;
  padding: 5px 15px 5px 15px;
  background: none;
  border-radius: 15px;
  margin-left: 2rem;
  cursor: pointer;
}
.bold-normal {
    font-weight: 600;
}
.component-subevent{
    border: 1px solid #CCCCCC;
    border-radius: 25px;
    padding: 1.5rem;
}
</style>
